import React from 'react';
import Header from '@/components/Header/Header';
import SubHeader from '@/components/About/SubHeader';
import PrivacyPolicePage from '@/components/PrivacyPolicy/PrivacyPolicyPage';

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <SubHeader title="Privacy Policy" />
      <PrivacyPolicePage />
    </>
  );
};

export default PrivacyPolicy;
