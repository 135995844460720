import React from "react";

type Props = {
  title: string;
};
const SubHeader = ({ title }: Props) => {
  return (
    <div
      className="subheader bg-cover bg-center dark-overlay"
      style={{ backgroundImage: "url('images/subheader.jpg')" }}
    >
      <div className="container">
        <div className="subheader-inner">
          <h1 className="text-white">{title}</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {title}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default SubHeader;
