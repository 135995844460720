import React from "react";
import { Link } from "gatsby";
import Image from "@/components/Image";

const Header = () => {
  return (
    <>
      <header className="main-header header-fw can-sticky header-1">
        <div className="top-header">
          <div className="top-header-inner">
            <ul className="social-media">
              <li>
                {" "}
                <a href="#">
                  {" "}
                  <i className="fab fa-facebook-f"></i>{" "}
                </a>{" "}
              </li>
              <li>
                {" "}
                <a href="#">
                  {" "}
                  <i className="fab fa-pinterest-p"></i>{" "}
                </a>{" "}
              </li>
              <li>
                {" "}
                <a href="#">
                  {" "}
                  <i className="fab fa-linkedin-in"></i>{" "}
                </a>{" "}
              </li>
              <li>
                {" "}
                <a href="#">
                  {" "}
                  <i className="fab fa-twitter"></i>{" "}
                </a>{" "}
              </li>
            </ul>
          </div>
        </div>

        <nav className="navbar">
          <a className="navbar-brand" href="index.html">
            {" "}
            {/* <img src="assets/img/logo.png" alt="logo" />{" "} */}
            <Image name="logo-transparent.png" classes="logo-img" />
          </a>
          <ul className="navbar-nav">
            <li className="menu-item menu-item-has-children">
              <Link to="/">Home</Link>
            </li>
            <li className="menu-item menu-item-has-children">
              <Link to="/about">About</Link>
            </li>
            <li className="menu-item menu-item-has-children mega-menu-wrapper">
              <Link to="/services">Services</Link>
            </li>
            <li className="menu-item menu-item-has-children mega-menu-wrapper">
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>

          <div className="header-controls">
            <div className="aside-toggler aside-trigger tr3">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
